import React from 'react';
import Select from 'react-select';

interface Version {
  value: string;
  label: string;
}

interface VersionSelectProps {
  currentVersion: string;
  handleVersionChange: (selectedOption: Version) => void;
}

const versionOptions: Version[] = [
  { value: 'v1', label: 'GIANT v1' },
  { value: 'v2', label: 'GIANT v2' },
];

export const VersionSelect: React.FC<VersionSelectProps> = ({
  currentVersion,
  handleVersionChange,
}) => {
  const onChange = (value: Version | null) => {
    if (value) {
      handleVersionChange(value);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <Select<Version>
        value={versionOptions.find(option => option.value === currentVersion)}
        onChange={onChange}
        options={versionOptions}
        isSearchable={false}
        className="version-select"
        classNamePrefix="version-select"
        styles={{
          container: provided => ({
            ...provided,
            width: '105px',
          }),
          dropdownIndicator: provided => ({
            ...provided,
            padding: '4px 4px', // Reduce padding here (default is 8px)
          }),
          control: provided => ({
            ...provided,
            minHeight: '34px', // Reduce from default 38px
            height: '34px', // Explicitly set height
          }),
          valueContainer: provided => ({
            ...provided,
            height: '35px',
            padding: '0 6px',
            display: 'flex',
            alignItems: 'center', // Centers the content vertically
          }),
          option: provided => ({
            ...provided,
            textAlign: 'left', // Ensure options align left
          }),
        }}
      />
    </div>
  );
};

export default VersionSelect;
