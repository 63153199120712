import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Alert, Button, ProgressBar } from 'react-bootstrap';
import Select from 'react-select';
import withStyles from 'isomorphic-style-loader/withStyles';

import {
  doMultiGeneSearch,
  getGeneListTSV,
  hasReservedUrlChars,
} from '../../../core/util';
import SearchBox from './SearchBox';
import ValidList from '../ValidList';
import s from './SearchBoxTable.css';
import Tooltip from '../../Tooltip';
import {
  clearCurrentSearch,
  clearRelevantNetworks,
  setCurrentSearchGenes,
  setCurrentSearchText,
  selectGiantVersion,
} from '../../../actions/NetworkActions';
import SearchExample from '../SearchExample';
import ApplicationContext from '../../ApplicationContext';
import { saveText } from '../../../core/save';
import GiantVersionSelect from '../../GiantVersionSelect';
import history, { updateHistory } from '../../../history';

const SearchBoxTable = ({
  allowBulkSearch,
  exampleGenes,
  exampleSearch,
  handleTargetSelect,
  initGenes,
  onBulkSearch,
  onSubmit,
  searchUrl,
  singleSearch,
}) => {
  const historyGiantVersion = history?.location?.state?.giantVersion;

  const globalContext = useContext(ApplicationContext);
  const dispatch = useDispatch();
  const [selectedGiantVersion, setSelectedGiantVersion] = useState(
    historyGiantVersion || 'v2',
  );
  const [isFetchingMulti, setIsFetchingMulti] = useState(false);
  const [showListWarning, setShowListWarning] = useState(false);
  const [showInvalidTextWarning, setShowInvalidTextWarning] = useState(false);
  const [value, setValue] = useState('');
  const [validList, setValidList] = useState(initGenes);

  useEffect(() => {
    if (singleSearch || initGenes.length === 0) {
      setValidList([]);
      dispatch(clearCurrentSearch());
      dispatch(clearRelevantNetworks());
    }
  }, []);

  const submitForm = useCallback(
    singleGene => {
      dispatch(selectGiantVersion(selectedGiantVersion));

      // Collect valid genes
      const genes =
        singleGene ||
        validList.map(e => ({
          entrez: e.matches[0].entrez,
          standard_name: e.matches[0].standard_name,
        }));

      // Submit form
      const data = { genes };
      if (onSubmit) {
        onSubmit(data);
      }
      dispatch(clearCurrentSearch());
    },
    [onSubmit, selectedGiantVersion, validList],
  );

  const handleSelect = useCallback(
    gene => {
      const { entrez } = gene;

      // Limit to only one gene in search if singleSearch is true
      if (singleSearch) {
        submitForm([gene]);
        return;
      }

      const idx = validList.findIndex(
        validGene => validGene.matches[0].entrez === entrez,
      );

      if (idx < 0) {
        // Reshape this to match format expected by ValidList
        const selectGene = {
          query: gene.standard_name,
          matches: [gene],
        };
        const tmpValidList = [...validList, selectGene];

        setValidList(tmpValidList);
        dispatch(setCurrentSearchGenes(tmpValidList));
      }
    },
    [validList],
  );

  const handleVersionChange = selectedOption => {
    setSelectedGiantVersion(selectedOption.value);
  };

  const handleSubmit = useCallback(
    e => {
      // Submit this form only on a click event (e.type == "click"), not on a key
      // press. Otherwise, the form gets submitted whenever the user hits "enter"
      // on a search term that doesn't match any genes.
      e.preventDefault();
      if (e.type === 'submit') {
        return;
      }
      // No need to validate number of genes since this function is only
      // accessible from the submit button, which only appears when there are
      // valid genes.
      submitForm();
    },
    [submitForm],
  );

  const handleListWarning = useCallback(
    (listWarningParam, searchText) => {
      if (listWarningParam) {
        if (hasReservedUrlChars(searchText)) {
          setShowInvalidTextWarning(true);
        } else if (allowBulkSearch) {
          setIsFetchingMulti(true);
          setShowInvalidTextWarning(false);
          doMultiGeneSearch(searchText, globalContext).then(result => {
            setIsFetchingMulti(false);
            setValidList([]);
            dispatch(setCurrentSearchText(searchText));
            dispatch(setCurrentSearchGenes(result));
            if (onBulkSearch) onBulkSearch();
          });
        } else {
          setShowListWarning(true);
          setShowInvalidTextWarning(false);
        }
      } else {
        setShowListWarning(false);
        setShowInvalidTextWarning(false);
      }
    },
    [allowBulkSearch, onBulkSearch],
  );

  const handleDelete = useCallback(
    i => {
      const newValidList = [...validList];
      newValidList.splice(i, 1);
      setValidList(newValidList);
      dispatch(setCurrentSearchGenes(newValidList));
    },
    [validList],
  );

  const listWarning =
    showListWarning || showInvalidTextWarning ? (
      showInvalidTextWarning ? (
        <Alert bsStyle="danger" style={{ width: '80%', margin: 'auto' }}>
          <i className="fa fa-exclamation-triangle" /> There are invalid
          characters in your query: {'{ }'} [ ] ~ : / | \ ? = ^ & % ` &apos;
          &quot; #. Please remove and try again.
        </Alert>
      ) : (
        <Alert className={s.goLeft} bsStyle="warning">
          <strong>
            <i className="fa fa-lightbulb-o" /> Search tip:
          </strong>{' '}
          Are you trying to search multiple genes from a list? If so, please use
          the
          <strong>Copy/paste genes</strong> tab above.
        </Alert>
      )
    ) : null;

  // Gene examples that when clicked, add genes to the search table
  const examples = ex => (
    <span key={ex.buttonText}>
      <Tooltip text={ex.description} id={ex.buttonText}>
        <Button
          onClick={() =>
            globalContext
              .fetch(ex.searchUrl)
              .then(response => response.json())
              .then(geneList => handleSelect(geneList[0]))
          }
          bsSize="xsmall"
          bsStyle="link"
        >
          {ex.buttonText}
        </Button>
      </Tooltip>
    </span>
  );

  const examplesAlert = (
    <div className={s.exampleAlert}>
      <i className="fa fa-lightbulb-o" />
      {singleSearch ? ' Example genes: ' : ' Example searches: '}
      {exampleGenes.map(examples)}
      {exampleSearch.map((ex, idx) => (
        <SearchExample
          example={ex}
          key={`example-${idx}`}
          clickFn={(e, data) => {
            setValue(data);
          }}
          isSomeText={Boolean(
            validList.length && ex.pasteText.split(/[ ,\n\t]/).length > 1,
          )} // ie. 'autism' does not trigger, however 'IL1B CAS9' will
        />
      ))}
    </div>
  );

  let query = null;
  let button = null;
  const searchString =
    validList.length === 1
      ? validList[0].matches[0].standard_name
      : `(${validList.length}) genes`;

  if (validList.length > 0 && !isFetchingMulti) {
    button = (
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: '0.5rem',
        }}
      >
        <div
          style={{
            display: initGenes.length === 0 ? 'flex' : 'none',
            justifyContent: 'flex-end',
          }}
        >
          <GiantVersionSelect
            handleVersionChange={handleVersionChange}
            currentVersion={selectedGiantVersion}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: initGenes.length === 0 ? 'flex-start' : 'center',
            gridColumn: initGenes.length === 0 ? '2' : '1 / span 2',
          }}
        >
          <Button bsStyle="primary" onClick={handleSubmit}>
            Search {searchString}
          </Button>
        </div>
      </div>
    );
    query = (
      <div className={s.query}>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
          <div style={{ textAlign: 'left' }}>
            <h3 className={s.goLeft}>Selected Genes</h3>
          </div>
          <div
            style={{
              justifySelf: 'end',
              alignSelf: 'center',
            }}
          >
            <Button
              bsStyle="default"
              onClick={() => {
                const geneListContent = getGeneListTSV(
                  validList.map(e => e.matches[0]),
                );
                saveText(geneListContent, `geneList-${Date.now()}.tsv`);
              }}
            >
              <span>
                <i className="fa fa-download" style={{ paddingRight: '5px' }} />
                Download Gene List
              </span>
            </Button>
          </div>
        </div>
        <ValidList data={validList} allowDelete onDelete={handleDelete} />
      </div>
    );
  }

  let progressBar = null;
  if (isFetchingMulti) {
    progressBar = (
      <div className={s.progressBar}>
        <ProgressBar
          active
          striped
          label="Validating query genes..."
          now={100}
        />
      </div>
    );
  }

  return (
    <div className={s.searchboxtable}>
      <form onSubmit={handleSubmit}>
        {!listWarning &&
          (exampleGenes.length > 0 || exampleSearch.length > 0) &&
          examplesAlert}
        <SearchBox
          onSelect={handleSelect}
          onListWarning={handleListWarning}
          onTargetSelect={handleTargetSelect}
          url={searchUrl}
          setValue={setValue}
          value={value}
        />
        {progressBar}
        {listWarning}
        {query}
        {button}
      </form>
    </div>
  );
};

SearchBoxTable.propTypes = {
  allowBulkSearch: PropTypes.bool,
  initGenes: PropTypes.arrayOf(PropTypes.object),
  handleTargetSelect: PropTypes.func,
  exampleGenes: PropTypes.arrayOf(PropTypes.object),
  exampleSearch: PropTypes.arrayOf(PropTypes.object),
  onSubmit: PropTypes.func,
  onBulkSearch: PropTypes.func,
  searchUrl: PropTypes.string,
  singleSearch: PropTypes.bool,
};

SearchBoxTable.defaultProps = {
  allowBulkSearch: false,
  handleTargetSelect: null,
  initGenes: [],
  exampleGenes: [],
  exampleSearch: [],
  onSubmit: null,
  onBulkSearch: null,
  searchUrl: '',
  singleSearch: false,
};

export default withStyles(s)(SearchBoxTable);
